import {
    createApp
} from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import './assets/tailwind.css';
import store from './store'// Import the store
import router from './router'

// createApp(App).use(router).use(store).mount('#app');
const app = createApp(App);

app.use(store);
app.use(router)
store.dispatch('fetchProducts').then(() => {
    app.mount('#app');
});