<template>
    <div class="bg-[#F8F8F8] h-screen flex flex-col">
        <!-- header -->
        <div class="flex-none bg-white flex flex-row justify-start items-center w-full h-16 max-h-20">
            <div @click="goBack()" class="w-fit ml-2">
                <img src="@/assets/arrow_back.svg" class="h-12 w-12" alt="arrow go back">
            </div>
            <h1 class="w-full text-gray-800 font-bold text-2xl text-center">عربة التسوق</h1>
        </div>

        <!-- products -->
        <div class="flex-grow overflow-y-auto px-2 py-4">
            <div class="flex flex-col gap-2">
                <div v-for="product in cartItems" :key="product[0].v"
                    class="w-full p-2 rounded-[10px] shadow-lg bg-white" dir="rtl">
                    <div class="flex flex-row gap-2">
                        <!-- product image -->
                        <img :src="product[3] ? product[3].v : require('@/assets/products/img-missing.svg')"
                            class="border border-gray-300 rounded-md" width="90px" height="82px" alt="">
                        <div class="flex flex-col mt-2 items-start">
                            <!-- product name -->
                            <span class="text-sm text-right" dir="ltr">{{ product[2].v }}</span>
                            <!-- product quantity -->
                            <div class="flex flex-row gap-2 items-start justify-center text-md mt-2">
                                <span class="bg-gray-400 rounded-[3px] text-white font-bold"
                                    @click="updateQuantity(product, product.quantity - 1)">
                                    <span class="p-1">-</span>
                                </span>
                                <span>{{ product.quantity }}</span>
                                <span class="bg-gray-400 rounded-[3px] text-white font-bold"
                                    @click="updateQuantity(product, product.quantity + 1)">
                                    <span class="p-1">+</span>
                                </span>
                            </div>
                            <!-- trash-can -->
                            <span class="text-md font-semibold" dir="ltr">{{ (product[6].v *
                                product.quantity).toFixed(2) }} DH</span>
                        </div>
                        <div class="ml-2 mr-auto h-full">
                            <div class="bg-gray-200 rounded-full p-1" @click="removeFromCart(product[0].v)">
                                <img src="@/assets/products/trash-can.svg" width="17px" height="17px" alt="">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cart details -->
        <div class="flex-none bg-white rounded-t-[22px] shadow-top w-full p-4" dir="rtl">
            <div class="flex flex-row text-black font-bold text-right mb-2 w-full">
                <p>تفاصيل المبلغ</p>
            </div>
            <div class="flex flex-row justify-between text-gray-500 font-medium">
                <p>مجموع الطلبيات</p>
                <p dir="ltr">{{ cartTotal }} DH</p>
            </div>
            <div class="flex flex-row justify-between text-gray-500 font-medium">
                <p>التخفيض</p>
                <p dir="ltr">-30 DH</p>
            </div>
            <div class="flex flex-row justify-between text-black font-bold mt-2">
                <p>المبلغ الإجمالي</p>
                <p dir="ltr">{{ cartTotal }} DH</p>
            </div>
            <button type="button" v-if="cartTotal > 0" @click="goToCheckout()"
                class="w-full h-15 mt-5 bg-green-600 py-2 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500">
                أطلب
            </button>
        </div>
    </div>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex';

export default {
    name: 'ShoppingCart',
    computed: {
        ...mapGetters(['cartItems', 'cartTotal']),
    },
    methods: {
        ...mapMutations(['removeFromCart', 'updateProductQuantity']),
        goBack() {
            window.history.back();
        },
        goToCheckout() {
            this.$router.push({ name: 'checkout' })
        },
        updateQuantity(product, quantity) {
            if (quantity > 0) {
                this.updateProductQuantity({ productId: product[0].v, quantity });
            }
        }
    }
};
</script>
<style scoped>
.shadow-top {
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    /* Customize shadow as needed */
}
</style>