<template>
    <!-- search input -->
    <div class="p-4">
        <div class="relative mb-2">
            <img src="@/assets/search-icon.svg" alt="Search Icon" class="w-6 h-6 absolute top-3 left-3">
            <input v-model="searchValue" ref="searchInput" @input="searchForProduct" type="text"
                placeholder="ابحث عن منتوج"
                class="w-full h-12 pl-10 pr-4 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-right" />
        </div>
    </div>

    <!-- helper text -->
    <div class="p-4 text-gray-700" dir="rtl">
        تم العثور على {{ filteredProducts.length }} منتج
    </div>

    <!-- show products -->
    <div class="grid grid-cols-2 gap-2 p-2">
        <div v-for="product in visibleProducts" :key="product[0].v"
            class="product-card flex-shrink-0 w-full h-auto border border-gray-200 rounded-lg p-4 shadow-md hover:shadow-lg transition-shadow duration-300">
            <!-- Image Container -->
            <div class="image-container mb-2 relative bg-gray-100 rounded overflow-hidden">
                <img :src="product[3] ? product[3].v : require('@/assets/products/img-missing.svg')" loading="lazy"
                    class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm"
                    alt="Product Image" />
                <span v-if="product[6].v < product[5].v"
                    class="absolute top-2 left-2 bg-red-500 text-white text-xs font-semibold rounded-md px-2" dir="ltr">
                    -{{ Math.round(((product[5].v - product[6].v) / product[5].v) * 100) }}%
                </span>
            </div>
            <!-- Product Name -->
            <div class="font-bold text-lg uppercase text-left text-wrapper">
                {{ product[2].v }}
            </div>
            <!-- Price and Add to Cart Button -->
            <div class="flex flex-row justify-between items-center mt-2">
                <span class="font-semibold text-lg text-black" dir="ltr">{{ product[6].v }} DH<span
                        class="text-xs font-normal">/{{ product[7].v }}</span></span>
                <button @click="addToCart(product)"
                    class="bg-green-500 text-white text-sm font-semibold rounded-full px-4 py-1 transition-colors duration-300 hover:bg-green-600"
                    dir="rtl">
                    إضافة
                </button>
            </div>
        </div>
        <!-- Sentinel element -->
        <div ref="sentinel" class="w-full h-1"></div>
    </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue';

export default {
    name: 'HomePage',
    components: { HeaderComponent },
    data() {
        return {
            searchValue: '',
            filteredProducts: [],
            visibleProducts: [],
            itemsPerPage: 6,
            currentPage: 1
        };
    },
    computed: {
        products() {
            return this.$store.getters.allProducts;
        }
    },
    async mounted() {
        this.searchValue = this.$route.query.searcheQuery || '';
        if (this.searchValue.length > 0)
            this.$refs.searchInput.focus();
        if (this.products.length === 0) {
            await this.$store.dispatch('fetchProducts');
        }

        if (this.searchValue.length > 0) {
            this.searchForProduct();
        }

        this.setupObserver();
    },
    methods: {
        addToCart(product) {
            this.$store.commit('addToCart', product);
        },
        searchForProduct() {
            const searchValue = this.searchValue.toLowerCase();
            if (searchValue.length > 0) {
                this.filteredProducts = this.products.filter(product => {
                    if (product.length >= 3 && typeof product[2].v === 'string') {
                        return product[2].v.toLowerCase().includes(searchValue);
                    }
                    return false;
                });
            } else {
                this.filteredProducts = this.products;
            }
            this.currentPage = 1;
            this.visibleProducts = this.filteredProducts.slice(0, this.itemsPerPage * this.currentPage);
        },
        loadMoreProducts() {
            this.currentPage++;
            const newProducts = this.filteredProducts.slice(0, this.itemsPerPage * this.currentPage);
            this.visibleProducts = newProducts;
        },
        setupObserver() {
            const options = {
                root: null,
                rootMargin: '0px',
                threshold: 1.0
            };

            const observer = new IntersectionObserver((entries) => {
                if (entries[0].isIntersecting && this.visibleProducts.length < this.filteredProducts.length) {
                    this.loadMoreProducts();
                }
            }, options);

            observer.observe(this.$refs.sentinel);
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

body {
    font-family: 'Cairo', sans-serif;
}

.text-wrapper {
    display: -webkit-box;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 2;
    /* Limits to 2 lines */
    line-height: 1.2em;
    /* Adjust as needed for line spacing */
    max-height: 2.4em;
    /* Should be line-height * 2 */
    width: 100%;
    /* Adjust as needed */
}

.placeholder-right::placeholder {
    text-align: right;
}
</style>
