import {
  createRouter,
  createWebHistory
} from 'vue-router';
import Login from '../views/Login.vue';
import ForgotPassword from '../views/ForgotPassword.vue';
import CreateAccount from '../views/CreateAccount.vue';
import Index from '../views/index.vue';
import Home from '../views/Home.vue'
import Products from '@/views/Products.vue';
import ShoppingCart from '@/views/ShoppingCart.vue';
import Checkout from '@/views/Checkout.vue';


const routes = [{
  path: '/login',
  name: 'login',
  component: Login
},
{
  path: '/forgot_password',
  name: 'ForgotPassword',
  component: ForgotPassword
},
{
  path: '/createAccount',
  name: 'createAccount',
  component: CreateAccount
},
{
  path: '/index',
  name: 'index',
  component: Index,
  meta: {
    requiresAuth: true
  },
  children: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: 'products',
      name: 'products',
      component: Products
    },
  ]
},
{
  path: '/shopping-cart',
  name: 'shopping-cart',
  component: ShoppingCart,
  meta: {
    requiresAuth: true
  },
},
{
  path: '/checkout',
  name: 'checkout',
  component: Checkout,
  meta: {
    requiresAuth: true
  },
}
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
});

// Navigation guard to check for authenticated routes
router.beforeEach((to, from, next) => {
  const isLoggedIn = !!localStorage.getItem('clientId'); // Example check for login status

  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (isLoggedIn) {
      // //control side bar
      // const sidebar = document.getElementById('logo-sidebar');
      // if (sidebar)
      //   sidebar.classList.toggle('-translate-x-full');
      next();
    } else {
      next({
        name: 'login'
      });
    }
  } else {
    next();
  }
});

export default router;