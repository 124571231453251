<template>
    <div class="min-h-screen flex items-center justify-center bg-white relative overflow-hidden">
        <img src="@/assets/Vector.svg" class="absolute top-0 left-0" alt="Top Left Decoration">
        <img src="@/assets/Vector1.svg" class="absolute bottom-0 right-0" alt="Bottom Right Decoration">

        <div class="w-full max-w-md p-8">
            <h1 class="text-3xl font-bold" :style="titleStyle">إنشاء حساب</h1>
            <h2 dir="rtl" class="mt-4 text-sm font-medium text-red-500" v-if="showMessage">{{ alertMessage }}</h2>

            <router-link to="login" class="absolute top-8 left-8">
                <img src="@/assets/arrow_back.svg" class="h-12 w-12" alt="arrow go back">
            </router-link>

            <form @submit.prevent="createAccount" class="mt-8">
                <div class="mb-4">
                    <input type="text" placeholder="الاسم الكامل" dir="rtl" v-model="name" id="name" ref="name"
                        :class="{ 'focus:ring-2': !nameShowMessage, 'border-red-500': nameShowMessage }"
                        class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-lg border focus:outline-none focus:ring-green-500">
                    <h2 dir="rtl" class="text-sm font-normal text-red-500" v-if="nameShowMessage">
                        {{ nameAlertMessage }}
                    </h2>
                </div>
                <div class="mb-4">
                    <div class="flex flex-row mb-1">
                        <input type="text" disabled
                            class="h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-l-lg w-[35%] border-r border-gray-500"
                            value="+212">
                        <input type="number" placeholder="رقم الهاتف" v-model="tel" id="tel" ref="tel"
                            :class="{ 'focus:ring-2': !telShowMessage, 'border-red-500': telShowMessage }"
                            class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-r-lg border focus:outline-none focus:ring-green-500">
                    </div>
                    <h2 dir="rtl" class="text-sm font-normal text-red-500" v-if="telShowMessage">
                        {{ telAlertMessage }}
                    </h2>
                </div>
                <div class="mb-4">
                    <textarea placeholder="العنوان" v-model="adresse" id="adresse" ref="adresse" dir="rtl"
                        :class="{ 'focus:ring-2': !adresseShowMessage, 'border-red-500': adresseShowMessage }"
                        class="w-full h-20 px-6 py-4 text-gray-700 bg-gray-100 rounded-lg border focus:outline-none focus:ring-green-500"></textarea>
                    <h2 dir="rtl" class="text-sm font-normal text-red-500" v-if="adresseShowMessage">
                        {{ adresseAlertMessage }}
                    </h2>
                </div>

                <button type="submit"
                    class="w-full h-15 mt-6 bg-green-600 py-2 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500">
                    إنشاء حساب
                </button>
                <p class="font-semibold text-center w-full mt-4" dir="rtl">
                    <span class="text-gray-500 ml-2">لديك حساب؟</span>
                    <router-link to="login">
                        <span class="text-blue-500">تسجيل الدخول</span>
                    </router-link>
                </p>
            </form>
        </div>
    </div>
</template>

<script>

export default {
    name: 'Login',
    data() {
        return {
            titleStyle: {
                fontFamily: 'Cairo',
                fontSize: '32px',
                fontWeight: '700',
                lineHeight: '40px',
                textAlign: 'center',
            },
            name: '',
            tel: '',
            adresse: '',
            showMessage: false,
            alertMessage: '',
            nameShowMessage: false,
            nameAlertMessage: '',
            telShowMessage: false,
            telAlertMessage: '',
            adresseShowMessage: false,
            adresseAlertMessage: '',
        };
    },
    methods: {
        validateInputs() {
            //reset all inputs
            this.nameShowMessage = false;
            this.telShowMessage = false;
            this.adresseShowMessage = false;
            //validate inputs
            if (this.name.length == 0) {
                this.nameShowMessage = true;
                this.nameAlertMessage = 'عليك أن تملأ اسمك الكامل';
                this.$refs.name.focus();
                return false;
            }

            if (this.tel.length === 0) {
                this.telShowMessage = true;
                this.telAlertMessage = 'عليك ملء رقم هاتفك';
                this.$refs.tel.focus();
                return false;
            } else {
                const phoneRegex = /^\d{9}$/;
                if (!phoneRegex.test(this.tel)) {
                    this.telShowMessage = true;
                    this.telAlertMessage = 'يجب أن يتكون رقم الهاتف من 9 أرقام';
                    this.$refs.tel.focus();
                    return false;
                }
            }

            if (this.adresse.length == 0) {
                this.adresseShowMessage = true;
                this.adresseAlertMessage = 'عليك أن تملأ عنوانك'
                return false;
            }

            return true;
        },

        async createAccount() {
            if (!this.validateInputs()) return;

            const base = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_SHEET_ID}/gviz/tq?&sheet=listClients`;
            const query = encodeURIComponent(`Select F,I where E='0${this.tel}'`);
            const url = `${base}&tq=${query}`;

            try {
                const res = await fetch(url);
                const rep = await res.text();
                const data = JSON.parse(rep.substr(47).slice(0, -2));

                if (data.table.rows.length >= 1) {
                    this.showMessage = true;
                    this.alertMessage = 'هذا الحساب موجود بالفعل، قم بتسجيل الدخول';
                    this.$refs.tel.focus();
                    return;
                } else {
                    // Send the client information to the API
                    const apiURL = `https://${process.env.VUE_APP_DOMAIN}/api/send-registration-email`; // Update with your API endpoint

                    const clientInfo = {
                        clientName: this.name,
                        clientTel: '0' + this.tel,
                        clientAddress: this.adresse
                    };

                    const apiResponse = await fetch(apiURL, {
                        method: 'POST',
                        headers: {
                            'Content-Type': 'application/json'
                        },
                        body: JSON.stringify(clientInfo)
                    });

                    if (apiResponse.ok) {
                        const responseData = await apiResponse.json();
                        console.log('API response:', responseData);
                        this.showMessage = true;
                        this.alertMessage = 'تم إنشاء الحساب بنجاح وتم إرسال المعلومات إلى قسم علاقات العملاء';
                    } else {
                        console.error('API error:', apiResponse.statusText);
                        this.showMessage = true;
                        this.alertMessage = 'حدث خطأ أثناء إرسال المعلومات إلى قسم علاقات العملاء';
                    }
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
                this.showMessage = true;
                this.alertMessage = 'حدث خطأ أثناء التحقق من وجود الحساب';
            }
        },

    },
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

body {
    font-family: 'Cairo', sans-serif;
}
</style>