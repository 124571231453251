<template>
    <div class="carousel-container relative w-full mb-4 overflow-hidden">
        <div ref="carousel" class="carousel flex transition-transform duration-700 ease-in-out">
            <div v-for="(banner, index) in banners" :key="index" class="min-w-full">
                <img :src="banner" alt="Banner" class="w-full h-auto rounded-lg">
            </div>
        </div>
        <button @click="prevSlide"
            class="carousel-button carousel-button-left absolute top-1/2 left-4 transform -translate-y-1/2 bg-white rounded-full p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-800" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M15 19l-7-7 7-7" />
            </svg>
        </button>
        <button @click="nextSlide"
            class="carousel-button carousel-button-right absolute top-1/2 right-4 transform -translate-y-1/2 bg-white rounded-full p-2">
            <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6 text-gray-800" fill="none" viewBox="0 0 24 24"
                stroke="currentColor">
                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M9 5l7 7-7 7" />
            </svg>
        </button>
    </div>
</template>


<script>
export default {
    data() {
        return {
            banners: [
                require('@/assets/banners/yawgi-banner.png'),
                'https://yawgidistribution.com/wp-content/uploads/2023/11/SLIDE-CROWN-CHEESE-SITE-YAWGI-1.png',
                'https://yawgidistribution.com/wp-content/uploads/2024/07/GRINGOOS.jpg'
            ],
            currentIndex: 0,
            intervalId: null,
        };
    },
    mounted() {
        this.startAutoSlide();
    },
    beforeDestroy() {
        this.stopAutoSlide();
    },
    methods: {
        nextSlide() {
            if (this.currentIndex < this.banners.length - 1) {
                this.currentIndex++;
            } else {
                this.currentIndex = 0;
            }
            this.updateSlide();
        },
        prevSlide() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
            } else {
                this.currentIndex = this.banners.length - 1;
            }
            this.updateSlide();
        },
        updateSlide() {
            const carousel = this.$refs.carousel;
            if (carousel) {
                carousel.style.transform = `translateX(-${this.currentIndex * 100}%)`;
            }
        },
        startAutoSlide() {
            this.intervalId = setInterval(this.nextSlide, 5000);
        },
        stopAutoSlide() {
            clearInterval(this.intervalId);
        }
    }
};
</script>


<style scoped>
.carousel-container {
    width: 100%;
    overflow: hidden;
    position: relative;
}

.carousel {
    display: flex;
    transition: transform 0.7s ease-in-out;
}

.carousel img {
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
}

.carousel-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    background-color: white;
    border-radius: 50%;
    padding: 0.5rem;
    cursor: pointer;
}

.carousel-button-left {
    left: 1rem;
}

.carousel-button-right {
    right: 1rem;
}
</style>
