<template>
    <div class="min-h-screen flex items-center justify-center bg-white relative overflow-hidden">
        <img src="@/assets/Vector.svg" class="absolute top-0 left-0" alt="Top Left Decoration">
        <img src="@/assets/Vector3.svg" class="absolute bottom-0 right-0" alt="Bottom Right Decoration">

        <div class="w-full max-w-md p-8">
            <router-link to="login" class="absolute top-8 left-8">
                <img src="@/assets/arrow_back.svg" class="h-12 w-12" alt="arrow go back">
            </router-link>

            <div class="mb-8">
                <h1 class="text-3xl font-bold" :style="titleStyle">نسيت كلمة السر</h1>
                <h2 dir="rtl" class="mt-4 text-sm font-medium text-red-500 text-center" v-if="showMessage">{{
                    alertMessage }}</h2>
            </div>

            <div v-if="!otpSent && !passwordReset">
                <form @submit.prevent="submitPhoneNumber" class="mt-8">
                    <div class="mb-4">
                        <div class="flex flex-row mb-1">
                            <input type="text" disabled
                                class="h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-l-lg w-[35%] border-r border-gray-500"
                                value="+212">
                            <input type="number" placeholder="رقم الهاتف" v-model="tel" id="tel" ref="tel"
                                class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-r-lg border focus:outline-none focus:ring-green-500">
                        </div>
                        <h2 dir="rtl" class="mt-4 text-sm font-medium text-gray-400 text-center">سوف
                            تتلقى رمزًا عبر "sms" في رقم هاتفك، لتتمكن من إعادة تعيين كلمة المرور الخاصة بك
                        </h2>
                    </div>
                    <button type="submit"
                        class="w-full mt-8 bg-green-600 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                        :style="buttonStyle">
                        أرسل رمز التحقق
                    </button>
                </form>
            </div>

            <div v-if="otpSent && !passwordReset">
                <form @submit.prevent="submitOtp" class="mt-8">
                    <div class="mb-4">
                        <input type="text" placeholder="رمز التحقق" v-model="otp" id="otp" ref="otp"
                            class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-lg border focus:outline-none focus:ring-green-500">
                    </div>
                    <button type="submit"
                        class="w-full mt-8 bg-green-600 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                        :style="buttonStyle">
                        تحقق
                    </button>
                </form>
            </div>

            <div v-if="passwordReset">
                <form @submit.prevent="resetPassword" class="mt-8">
                    <div class="mb-4">
                        <input type="password" placeholder="كلمة السر الجديدة" v-model="newPassword" id="newPassword"
                            ref="newPassword"
                            class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-lg border focus:outline-none focus:ring-green-500">
                    </div>
                    <button type="submit"
                        class="w-full mt-8 bg-green-600 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500"
                        :style="buttonStyle">
                        إعادة تعيين كلمة السر
                    </button>
                </form>
            </div>

            <div ref="recaptcha" class="recaptcha"></div>
        </div>
    </div>
</template>

<script>
import { signInWithPhoneNumber, RecaptchaVerifier } from 'firebase/auth';
import { auth } from '../firebase'; // Adjust the path if necessary

export default {
    name: 'ForgotPassword',
    data() {
        return {
            titleStyle: {
                fontFamily: 'Cairo',
                fontSize: '32px',
                fontWeight: '700',
                lineHeight: '40px',
                textAlign: 'center',
            },
            buttonStyle: {
                padding: '18px 24px',
                gap: '16px',
                borderRadius: '68px',
                opacity: '1',
            },
            showMessage: false,
            alertMessage: '',
            tel: '',
            otp: '',
            newPassword: '',
            otpSent: false,
            passwordReset: false,
            confirmationResult: null
        };
    },
    methods: {
        async submitPhoneNumber() {
            this.showMessage = false;
            this.alertMessage = '';
            if (!this.tel) {
                this.showMessage = true;
                this.alertMessage = 'عليك ملء رقم هاتفك';
                return;
            }

            if (this.tel.length > 9) {
                this.showMessage = true;
                this.alertMessage = 'يجب أن يتكون رقم الهاتف من 9 أرقام فقط';
                return;
            }

            const base = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_SHEET_ID}/gviz/tq?&sheet=listClients`;
            const query = encodeURIComponent(`Select F,I where E='0${this.tel}'`);
            const url = `${base}&tq=${query}`;

            try {
                const res = await fetch(url);
                const rep = await res.text();
                const data = JSON.parse(rep.substr(47).slice(0, -2));

                if (data.table.rows.length === 0) {
                    this.showMessage = true;
                    this.alertMessage = 'لم يتم العثور على حساب برقم الهاتف هذا،';
                    return;
                }

                this.setupRecaptcha();
                this.phoneAuth();
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        },
        setupRecaptcha() {
            if (typeof window.recaptchaVerifier === 'undefined') {
                window.recaptchaVerifier = new RecaptchaVerifier(this.$refs.recaptcha, {
                    'size': 'invisible',
                    'callback': (response) => {
                        this.phoneAuth();
                    }
                }, auth);
            }
        },
        phoneAuth() {
            const phoneNumber = `+212${this.tel}`;
            const appVerifier = window.recaptchaVerifier;

            signInWithPhoneNumber(auth, phoneNumber, appVerifier)
                .then((confirmationResult) => {
                    this.otpSent = true;
                    this.confirmationResult = confirmationResult;
                })
                .catch((error) => {
                    this.alertMessage = error.message;
                });
        },
        submitOtp() {
            if (!this.otp) {
                this.alertMessage = 'عليك ملء رمز التحقق';
                return;
            }

            this.confirmationResult.confirm(this.otp)
                .then((result) => {
                    this.passwordReset = true;
                })
                .catch((error) => {
                    this.alertMessage = 'رمز التحقق غير صحيح';
                });
        },
        resetPassword() {
            if (!this.newPassword) {
                this.alertMessage = 'عليك ملء كلمة السر الجديدة';
                return;
            }

            // Implement the password reset logic here

            this.$router.push({ name: 'home' });
        }
    }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

body {
    font-family: 'Cairo', sans-serif;
}
</style>
