import { createStore } from 'vuex';
import createPersistedState from 'vuex-persistedstate';

export default createStore({
    state: {
        cart: [],
        products: [],
        productsFetched: false
    },
    mutations: {
        addToCart(state, product) {
            // Check if the product already exists in the cart
            const existingProduct = state.cart.find(item => item[0].v === product[0].v);
            if (existingProduct) {
                existingProduct.quantity += 1;
            } else {
                // If not, add it to the cart with an initial quantity of 1
                state.cart.push({ ...product, quantity: 1 });
            }
        },
        removeFromCart(state, productId) {
            state.cart = state.cart.filter(product => product[0].v !== productId);
        },
        updateProductQuantity(state, { productId, quantity }) {
            const product = state.cart.find(product => product[0].v === productId);
            if (product) {
                product.quantity = quantity;
            }
        },
        setProducts(state, products) {
            state.products = products;
            state.productsFetched = true;
        },
        CLEAR_CART(state) {
            state.cart = [];
        }
    },
    getters: {
        cartItems: state => state.cart,
        cartTotal: state => state.cart.reduce((total, product) => total + product[6].v * product.quantity, 0).toFixed(2),
        nbrOfItems: state => state.cart.reduce((total, product) => total + product.quantity, 0),
        allProducts: state => state.products
    },
    actions: {
        async fetchProducts({ commit, state }) {
            if (state.productsFetched) return;

            const base = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_SHEET_ID}/gviz/tq?&sheet=produits_copy`;
            const query = encodeURIComponent('Select A,C,D,E,F,G,J,P');
            const url = `${base}&tq=${query}`;

            try {
                const res = await fetch(url);
                const rep = await res.text();
                const data = JSON.parse(rep.substr(47).slice(0, -2));
                const products = data.table.rows.map(row => row.c);
                commit('setProducts', products);
            } catch (error) {
                console.error('Error fetching data: ', error);
            }
        },
        clearCart({ commit }) {
            commit('CLEAR_CART');
        }
    },
    plugins: [createPersistedState()]
});
