<template>
    <div class="min-h-screen flex items-center justify-center bg-white relative overflow-hidden">
        <img src="@/assets/Vector.svg" class="absolute top-0 left-0" alt="Top Left Decoration">
        <img src="@/assets/Vector1.svg" class="absolute bottom-0 right-0" alt="Bottom Right Decoration">

        <div class="w-full max-w-md p-8">
            <h1 class="text-3xl font-bold" :style="titleStyle">تسجيل الدخول</h1>
            <h2 dir="rtl" class="mt-4 text-sm font-medium text-red-500" v-if="showMessage">{{ alertMessage }}</h2>

            <form @submit.prevent="login" class="mt-8">
                <div class="mb-4">
                    <div class="flex flex-row mb-1">
                        <input type="text" disabled
                            class="h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-l-lg w-[35%] border-r border-gray-500"
                            value="+212">
                        <input type="number" placeholder="رقم الهاتف" v-model="tel" id="tel" ref="tel"
                            :class="{ 'focus:ring-2': !telShowMessage, 'border-red-500': telShowMessage }"
                            class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-r-lg border focus:outline-none focus:ring-green-500">
                    </div>
                    <h2 dir="rtl" class="text-sm font-normal text-red-500" v-if="telShowMessage">
                        {{ telAlertMessage }}
                    </h2>
                </div>
                <div>
                    <div class="relative">
                        <input :type="passwordFieldType" placeholder="كلمة السر" v-model="password" id="password"
                            ref="password"
                            :class="{ 'focus:ring-2': !passwordShowMessage, 'border-red-500': passwordShowMessage }"
                            class="w-full h-15 px-6 py-4 text-gray-700 bg-gray-100 rounded-lg border focus:outline-none focus:ring-green-500">
                        <img :src="eyeIcon" class="absolute top-5 right-4 cursor-pointer"
                            alt="Toggle Password Visibility" @click="togglePasswordVisibility">
                    </div>
                    <h2 dir="rtl" class="text-sm font-normal text-red-500" v-if="passwordShowMessage">
                        {{ passwordAlertMessage }}
                    </h2>
                </div>
                <router-link to="forgot_password" class="block text-right text-sm font-semibold text-gray-600 mt-6">نسيت
                    كلمة السر ؟</router-link>

                <button type="submit"
                    class="w-full h-15 mt-6 bg-green-600 py-2 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500">
                    تسجيل الدخول
                </button>

                <p class="font-semibold text-center w-full mt-4" dir="rtl">
                    <span class="text-gray-500 ml-2">ليس لديك حساب؟</span>
                    <router-link to="createAccount">
                        <span class="text-blue-500">إنشاء حساب</span>
                    </router-link>
                </p>
            </form>
        </div>
    </div>
</template>

<script>
import eyeHide from '@/assets/eyeHide.svg';
import eyeShow from '@/assets/eyeShow.svg';

export default {
    name: 'Login',
    data() {
        return {
            passwordVisible: false,
            titleStyle: {
                fontFamily: 'Cairo',
                fontSize: '32px',
                fontWeight: '700',
                lineHeight: '40px',
                textAlign: 'center',
            },
            tel: '',
            password: '',
            showMessage: false,
            alertMessage: '',
            telShowMessage: false,
            telAlertMessage: '',
            passwordShowMessage: false,
            passwordAlertMessage: '',
        };
    },
    computed: {
        passwordFieldType() {
            return this.passwordVisible ? 'text' : 'password';
        },
        eyeIcon() {
            return this.passwordVisible ? eyeHide : eyeShow;
        }
    },
    methods: {
        togglePasswordVisibility() {
            this.passwordVisible = !this.passwordVisible;
        },

        validateInputs() {
            if (this.tel.length === 0) {
                this.telShowMessage = true;
                this.telAlertMessage = 'عليك ملء رقم هاتفك';
                this.$refs.tel.focus();
                return false;
            } else {
                const phoneRegex = /^\d{9}$/;
                if (!phoneRegex.test(this.tel)) {
                    this.telShowMessage = true;
                    this.telAlertMessage = 'يجب أن يتكون رقم الهاتف من 9 أرقام';
                    this.$refs.tel.focus();
                    return false;
                } else {
                    this.telShowMessage = false;
                    this.telAlertMessage = '';
                }
            }

            if (this.password.length === 0) {
                this.passwordShowMessage = true;
                this.passwordAlertMessage = 'عليك ملء كلمة المرور الخاصة بك';
                this.$refs.password.focus();
                return false;
            } else {
                this.passwordShowMessage = false;
                this.passwordAlertMessage = '';
            }

            return true;
        },

        async login() {
            if (!this.validateInputs()) return;

            const base = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_SHEET_ID}/gviz/tq?&sheet=listClients`;
            const query = encodeURIComponent(`Select F,I where E='0${this.tel}'`);
            const url = `${base}&tq=${query}`;

            try {
                const res = await fetch(url);
                const rep = await res.text();
                const data = JSON.parse(rep.substr(47).slice(0, -2));

                if (data.table.rows.length === 0) {
                    this.showMessage = true;
                    this.alertMessage = 'لم يتم العثور على حساب برقم الهاتف هذا، تحقق مرة أخرى من الرقم أو أنشئ حسابًا جديدًا';
                    this.telShowMessage = true;
                    this.$refs.tel.focus();
                    return;
                }

                if (data.table.rows.length === 1) {
                    this.handleSingleAccount(data.table.rows[0]);
                } else {
                    this.handleMultipleAccounts(data.table.rows);
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        },

        handleSingleAccount(row) {
            this.showMessage = false;
            this.alertMessage = '';

            if (row.c[0].v !== this.password) {
                this.passwordShowMessage = true;
                this.passwordAlertMessage = 'كلمة السر خاطئة';
            } else {
                const clientId = row.c[1].v;
                console.log(clientId);
                localStorage.setItem('clientId', clientId);
                this.$router.push({ name: 'home' });
            }
        },

        handleMultipleAccounts(rows) {
            this.showMessage = false;
            this.alertMessage = '';

            let validAccountFound = false;

            rows.forEach(row => {
                if (row.c[0].v === this.password) {
                    const clientId = row.c[1].v;
                    console.log(clientId);
                    localStorage.setItem('clientId', clientId);
                    this.$router.push({ name: 'home' });
                    validAccountFound = true;
                }
            });

            if (!validAccountFound) {
                this.passwordShowMessage = true;
                this.passwordAlertMessage = 'كلمة السر خاطئة';
            }
        },

        async checkIfUserHasLoggedInThePast() {
            const clientId = localStorage.getItem('clientId')
            if (clientId) {
                const base = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_SHEET_ID}/gviz/tq?&sheet=listClients`;
                const query = encodeURIComponent(`Select I where I='${clientId}'`);
                const url = `${base}&tq=${query}`;

                try {
                    const res = await fetch(url);
                    const rep = await res.text();
                    const data = JSON.parse(rep.substr(47).slice(0, -2));
                    if (data.table.rows.length > 0) {
                        this.$router.push({ name: 'home' });
                    }
                } catch (error) {
                    console.error("Error fetching data: ", error);
                }
            }
        },
    },
    mounted() {
        this.checkIfUserHasLoggedInThePast()
    }
}
</script>
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

body {
    font-family: 'Cairo', sans-serif;
}
</style>