<template>
  <HeaderComponent />
  <div class="min-h-screen bg-[#F8F8F8] mt-16">
    <router-view></router-view>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue'
export default {
  // name: 'HomePage',
  components: { HeaderComponent },
  data() {
    return {
    };
  },
  computed: {
  },
  mounted() {
  },
  methods: {
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

body {
  font-family: 'Cairo', sans-serif;
}

.text-wrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* Limits to 2 lines */
  line-height: 1.2em;
  /* Adjust as needed for line spacing */
  max-height: 2.4em;
  /* Should be line-height * 2 */
  width: 100%;
  /* Adjust as needed */
}

.placeholder-right::placeholder {
  text-align: right;
}

/* Add any additional styling here */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.product-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pill-button {
  flex: 0 0 auto;
  white-space: nowrap;
}
</style>
