<template>
    <div>
        <!-- Header -->
        <header class="flex justify-between items-center p-4 bg-white shadow fixed top-0 z-50 w-full">
            <div class="flex items-center gap-2">
                <div class="rounded-full w-8 h-8 overflow-hidden">
                    <img src="https://domicar.lvmanager.net/admin/contratPreviewAssets/profile%20icon.png"
                        alt="Profile Icon" class="w-full h-full object-cover">
                </div>
                <div class="relative" @click="navigateToShoppingcart()">
                    <img src="@/assets/shopping-cart.svg" alt="Shopping Cart" class="w-8 h-8">
                    <span v-if="nbrOfItems > 0"
                        class="absolute top-0 right-0 bg-red-500 text-white text-xs rounded-full w-5 h-5 flex items-center justify-center">
                        {{ nbrOfItems }}
                    </span>
                </div>
            </div>
            <div class="text-green-600 font-bold text-xl bg-gray-200 px-3 py-1 rounded-full">2400 DH</div>
            <img src="@/assets/burger-menu.svg" alt="Burger Menu" class="w-8 h-8 cursor-pointer" @click="openPanel">
        </header>

        <!-- Overlay -->
        <div v-if="isPanelOpen" class="fixed inset-0 bg-black bg-opacity-50 z-40" @click="closePanel"></div>

        <!-- Side Panel -->
        <transition name="slide" dir="rtl">
            <div v-if="isPanelOpen" class="fixed inset-y-0 right-0 bg-white w-72 p-4 z-50 shadow-lg">
                <div class="flex justify-between items-center">
                    <img src="@/assets/side-panel-icons/yawgi-icon.svg" alt="Logo" class="w-10 h-10">
                    <button @click="closePanel">
                        <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none" viewBox="0 0 24 24"
                            stroke="currentColor">
                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                d="M6 18L18 6M6 6l12 12" />
                        </svg>
                    </button>
                </div>
                <nav class="mt-4">
                    <ul>
                        <li class="mb-4 rounded-md p-1">
                            <a href="#" class="flex items-center gap-2">
                                <i class="icon-settings"></i>
                                <span>الإعدادات</span>
                            </a>
                        </li>
                        <li class="mb-4 rounded-md p-1">
                            <a href="#" class="flex items-center gap-2">
                                <i class="icon-cart"></i>
                                <span>سلة المنتجات</span>
                            </a>
                        </li>
                        <li class="mb-4 rounded-md p-1">
                            <a href="#" class="flex items-center gap-2">
                                <i class="icon-gift"></i>
                                <span>الرصيد</span>
                            </a>
                        </li>
                        <li @click="toggleSubmenu" :class="{ 'bg-blue-100': isSubmenuOpen }"
                            class="mb-4 rounded-md p-1 cursor-pointer flex items-center justify-between">
                            <span class="flex items-center gap-2">
                                <i class="icon-box"></i>
                                أنواع المنتجات
                            </span>
                            <svg xmlns="http://www.w3.org/2000/svg" :class="{ 'transform rotate-180': isSubmenuOpen }"
                                class="h-6 w-6 transition-transform duration-200" fill="none" viewBox="0 0 24 24"
                                stroke="currentColor">
                                <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                                    d="M6 9l6 6 6-6" />
                            </svg>
                        </li>
                        <transition name="slide">
                            <ul v-if="isSubmenuOpen" class="mr-6">
                                <li class="mb-4 rounded-md p-1">
                                    <a href="#" class="flex items-center">
                                        مشروبات
                                    </a>
                                </li>
                                <li class="mb-4 rounded-md p-1">
                                    <a href="#" class="flex items-center">
                                        <i class="icon-snack"></i>
                                        مشروبات
                                    </a>
                                </li>
                                <li class="mb-4 rounded-md p-1">
                                    <a href="#" class="flex items-center">
                                        <i class="icon-food"></i>
                                        مشروبات
                                    </a>
                                </li>
                            </ul>
                        </transition>
                    </ul>
                </nav>
            </div>
        </transition>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    name: 'HeaderComponent',
    data() {
        return {
            isPanelOpen: false,
            isSubmenuOpen: false,
        };
    },
    computed: {
        ...mapGetters(['nbrOfItems']),
    },
    methods: {
        openPanel() {
            this.isPanelOpen = true;
        },
        closePanel() {
            this.isPanelOpen = false;
        },
        toggleSubmenu() {
            this.isSubmenuOpen = !this.isSubmenuOpen;
        },
        navigateToShoppingcart() {
            this.$router.push({ name: 'shopping-cart' });
        }
    }
};
</script>

<style scoped>
.icon-settings::before {
    content: url('@/assets/side-panel-icons/params-icon.svg');
}

.icon-cart::before {
    content: url('@/assets/side-panel-icons/shopping-bag-icon.svg');
}

.icon-gift::before {
    content: url('@/assets/side-panel-icons/wallet-icon.svg');
}

.icon-box::before {
    content: url('@/assets/side-panel-icons/products-icon.svg');
}

.slide-enter-active,
.slide-leave-active {
    transition: transform 0.3s ease;
}

.slide-enter,
.slide-leave-to {
    transform: translateX(100%);
}
</style>
