<template>
  <div class="min-h-screen bg-[#F8F8F8] mt-16">
    <!-- searche component -->
    <div class="p-4">
      <div class="relative mb-2">
        <img src="@/assets/search-icon.svg" alt="Search Icon" class="w-6 h-6 absolute top-3 left-3">
        <input type="text" placeholder="ابحث عن منتوج" @input="handleInput"
          class="w-full h-12 pl-10 pr-4 border rounded-full focus:outline-none focus:ring-2 focus:ring-green-500 placeholder-right">
      </div>
    </div>

    <!-- banner carousel component -->
    <CarouselComponent />

    <div class="p-4">
      <div ref="scrollContainer" class="flex space-x-2 overflow-x-auto mb-4 no-scrollbar py-4">
        <div v-for="(button, index) in infiniteButtons" :key="index" class="flex-shrink-0">
          <button :class="{
            'bg-green-600 text-white': button === 'Beverages',
            'bg-gray-200 text-black': button !== 'Beverages'
          }" class="py-2 px-4 rounded-full focus:outline-none whitespace-nowrap">
            {{ button }}
          </button>
        </div>
      </div>


      <div class="w-full flex gap-2 overflow-x-auto no-scrollbar mb-4" style="direction: rtl;">

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcTuQg1_w2qUGtce4aqUiwxTCvpZ_4PpKFdA2w&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-md">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            PEPSI
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img loading="lazy"
              src="https://i5.walmartimages.com/asr/c5dda0a3-dc8a-4778-9680-7a039d75fb1b.22b1cac539e687d9d8e31384df3fa1c3.jpeg?odnHeight=768&odnWidth=768&odnBg=FFFFFF"
              alt="Aquafina 5l*2" class="product-image w-full h-40 object-cover border border-gray-300 rounded-md">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('Aquafina 5l*2'), 'text-left': !/[\u0600-\u06FF]/.test('Aquafina 5l*2') }">
            Aquafina
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSgpramZHyewmyxjNz7IZydkflahyHBGfNkrQ&s"
              loading="lazy" alt="Aquafina 5l*2"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-md">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('Aquafina 5l*2'), 'text-left': !/[\u0600-\u06FF]/.test('Aquafina 5l*2') }">
            Doritos
          </div>
        </div>

        <!-- Add more product cards as needed -->
      </div>



      <h2 class="text-xl font-bold mb-4" dir="rtl">الأكثر مبيعا</h2>
      <div class="w-full flex gap-2 overflow-x-auto no-scrollbar mb-4" style="direction: rtl;">

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSACQXmMvJMLIihG3CRiihhPD9XtSJO7xtRoA&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            7up 100cl x12
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">30 DH<span>/علبة</span></span>
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRUrEkpKGgJQ7N8tS5ABWawr1A0oMqrY3oDUQ&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            AQUAFINA 5L PACK 2
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">9٫58 DH<span>/علبة</span></span>
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://assets.tops.co.th/MIRINDA-MirindaOrangeFlavor440ml-8858998582020-1" alt="Coca Cola"
              loading="lazy" class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            MIRINDA PLUS ANANAS  1L*8
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">6.57 DH<span>/علبة</span></span>
          </div>
        </div>

        <!-- Add more product cards as needed -->
      </div>

      <h2 class="text-xl font-bold mb-4" dir="rtl">منتجات جديدة</h2>
      <div class="w-full flex gap-2 overflow-x-auto no-scrollbar mb-4" style="direction: rtl;">

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN1guT6reh5IHr3qfFNge21gJTY-1DmfYLNQ&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            PRIME BLUE BERRY 500ML
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">25 DH<span>/علبة</span></span>
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://yawgidistribution.com/wp-content/uploads/2023/11/yawgi-butter-beurre.png" alt="Coca Cola"
              loading="lazy" class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            YAWGUI BEURRE 2,5k
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">187,5 DH<span>/وحدة</span></span>
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiyK6ufFsYuupoPVrGJVv6c6gKNGlkJce1Ng&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            STING BERRY BLAST CAN 25CL*12
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">5 DH<span>/علبة</span></span>
          </div>
        </div>

        <!-- Add more product cards as needed -->
      </div>

      <h2 class="text-xl font-bold mb-4" dir="rtl">عروض جديدة</h2>
      <div class="w-full flex gap-2 overflow-x-auto no-scrollbar mb-4" style="direction: rtl;">

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1 relative">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRE--5m2hthdvJGiFqxsSc_C6aguJsSx8sCvg&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
            <span class="absolute top-2 left-2 bg-red-500  rounded-md px-2 text-white" dir="ltr">-15%</span>
          </div>

          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            ROSTIKA CHILI 36 P
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">24.17 DH<span>/وحدة</span></span>
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container relative mb-1">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQN4SdJpQmpMBaHLrV4p9HVr-H4QAQ7Cgbprw&s"
              alt="Coca Cola" loading="lazy"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
            <span class="absolute top-2 left-2 bg-red-500  rounded-md px-2 text-white" dir="ltr">-10%</span>
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            PEPSI SANS SUCRE 33 CL CAN*12
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">3.67 DH<span>/علبة</span></span>
          </div>
        </div>

        <div class="product-card flex-shrink-0 w-60 h-auto border border-gray-300 rounded-lg p-2">
          <div class="image-container mb-1 relative">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRiyK6ufFsYuupoPVrGJVv6c6gKNGlkJce1Ng&s"
              loading="lazy" alt="Coca Cola"
              class="product-image w-full h-40 object-cover border border-gray-300 rounded-sm">
            <span class="absolute top-2 left-2 bg-red-500  rounded-md px-2 text-white" dir="ltr">-20%</span>
          </div>
          <div class="font-bold text-wrapper uppercase"
            :class="{ 'text-right': /[\u0600-\u06FF]/.test('CHARCUTERIE'), 'text-left capitalize': !/[\u0600-\u06FF]/.test('CHARCUTERIE') }">
            STING 25 CL CAN BERRY BLAST*12
          </div>
          <div class="flex flex-row justify-between items-center mt-2">
            <button class="bg-green-500 text-white text-lg font-semibold rounded-full px-3" dir="rtl">إضافة</button>
            <span class="font-bold text-black" dir="ltr">5 DH<span>/علبة</span></span>
          </div>
        </div>

      </div>

    </div>
  </div>
</template>

<script>
import HeaderComponent from '../components/HeaderComponent.vue'
import CarouselComponent from '@/components/CarouselComponent.vue';
export default {
  name: 'HomePage',
  components: { HeaderComponent, CarouselComponent },
  data() {
    return {
      buttons: ['Beverages', 'Drinks', 'Snacks', 'Foods', 'Dairy', 'Bakery'],
    };
  },
  computed: {
    infiniteButtons() {
      // Create an infinitely long array of buttons
      return Array.from({ length: 1000 }, (_, i) => this.buttons[i % this.buttons.length]);
    }
  },
  mounted() {
    const scrollContainer = this.$refs.scrollContainer;
    const middlePosition = scrollContainer.scrollWidth / 2;
    scrollContainer.scrollLeft = middlePosition;

    scrollContainer.addEventListener('scroll', () => {
      const scrollWidth = scrollContainer.scrollWidth;
      const scrollLeft = scrollContainer.scrollLeft;
      const clientWidth = scrollContainer.clientWidth;

      if (scrollLeft <= 0) {
        scrollContainer.scrollLeft = middlePosition;
      } else if (scrollLeft + clientWidth >= scrollWidth) {
        scrollContainer.scrollLeft = middlePosition - clientWidth;
      }
    });
  },
  methods: {
    handleInput(event) {
      const query = event.target.value;
      if (query) {
        this.$router.push({ name: 'products', query: { searcheQuery: query } });
      }
    }
  }
};
</script>

<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@700&display=swap');

body {
  font-family: 'Cairo', sans-serif;
}

.text-wrapper {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  /* Limits to 2 lines */
  line-height: 1.2em;
  /* Adjust as needed for line spacing */
  max-height: 2.4em;
  /* Should be line-height * 2 */
  width: 100%;
  /* Adjust as needed */
}

.placeholder-right::placeholder {
  text-align: right;
}

/* Add any additional styling here */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.product-card {
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Hide scrollbar */
.no-scrollbar::-webkit-scrollbar {
  display: none;
}

.no-scrollbar {
  -ms-overflow-style: none;
  /* IE and Edge */
  scrollbar-width: none;
  /* Firefox */
}

.pill-button {
  flex: 0 0 auto;
  white-space: nowrap;
}
</style>
