// src/firebase.js

import { initializeApp } from 'firebase/app';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

// Your Firebase configuration
const firebaseConfig = {
    apiKey: "AIzaSyBhxTANOWKAbGhhmCzrpiyoBfExQTp8xWY",
    authDomain: "yawgi-prj.firebaseapp.com",
    projectId: "yawgi-prj",
    storageBucket: "yawgi-prj.appspot.com",
    messagingSenderId: "791307034844",
    appId: "1:791307034844:web:4001b539b03982aa275419"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, RecaptchaVerifier, signInWithPhoneNumber };