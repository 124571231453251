<template>
    <div class="bg-[#F8F8F8] h-screen flex flex-col">
        <!-- header -->
        <div class="flex-none bg-white flex flex-row justify-start items-center w-full h-16 max-h-20">
            <div @click="goBack" class="w-fit ml-2">
                <img src="@/assets/arrow_back.svg" class="h-12 w-12" alt="arrow go back">
            </div>
            <h1 class="w-full text-gray-800 font-bold text-2xl text-center">تأكيد الطلب</h1>
        </div>

        <!-- products -->
        <div class="flex-grow overflow-y-auto px-2 py-4">
            <!-- client details -->
            <div class="w-full p-3 rounded-[10px] shadow-lg bg-white mb-10">
                <div class="flex flex-row justify-between">
                    <div class="h-full">
                        <img src="@/assets/edit.svg" alt="edit">
                    </div>
                    <div class="flex flex-col items-end">
                        <span class="text-lg font-bold">المعلومات الشخصية</span>
                        <span v-if="clientName === '' && clientTel === ''" class="text-red-600 font-normal"> غير
                            متوفرة</span>
                        <span class="text-gray-600 font-normal"
                            :class="{ 'text-right': /[\u0600-\u06FF]/.test(clientName), 'text-left capitalize': !/[\u0600-\u06FF]/.test(clientName) }">
                            {{ clientName }}
                        </span>
                        <span class="text-gray-600 font-normal" dir="ltr">{{ clientTel }}</span>
                    </div>
                </div>
            </div>

            <div class="flex flex-col gap-2">
                <div v-for="product in cartItems" :key="product[0].v"
                    class="w-full p-2 rounded-[10px] shadow-lg bg-white" dir="rtl">
                    <div class="flex flex-row">
                        <!-- product image -->
                        <img :src="product[3] ? product[3].v : require('@/assets/products/img-missing.svg')"
                            class="border border-gray-300 rounded-md" width="90px" height="82px" alt="product image">
                        <div class="mr-2 flex flex-col mt-2 items-start">
                            <!-- product name -->
                            <span class="text-sm text-right" dir="ltr">{{ product[2].v }}</span>
                            <span class="text-sm text-right" dir="ltr">{{ product.quantity }} {{ product[7].v }}</span>
                            <span class="text-md font-semibold" dir="ltr">{{ (product[6].v *
                                product.quantity).toFixed(2) }} DH</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- cart details -->
        <div class="flex-none bg-white rounded-t-[22px] shadow-top w-full p-4" dir="rtl">
            <div class="flex flex-row text-black font-bold text-right mb-2 w-full">
                <p>تفاصيل المبلغ</p>
            </div>
            <div class="flex flex-row justify-between text-gray-500 font-medium">
                <p>مجموع الطلبيات</p>
                <p dir="ltr">{{ cartTotal }} DH</p>
            </div>
            <div class="flex flex-row justify-between text-gray-500 font-medium">
                <p>TVA(20%)</p>
                <p dir="ltr">{{ (parseFloat(cartTotal * 0.2)).toFixed(2) }} DH</p>
            </div>
            <div class="flex flex-row justify-between text-black font-bold mt-2">
                <p>المبلغ الإجمالي</p>
                <p dir="ltr">{{ ((parseFloat(cartTotal) + (cartTotal * 0.2))).toFixed(2) }} DH</p>
            </div>
            <button type="button" @click="placeOrder"
                class="w-full h-15 mt-5 bg-green-600 py-2 text-white font-semibold text-lg rounded-full focus:outline-none focus:ring-2 focus:ring-green-500">
                تأكيد
            </button>
        </div>
    </div>

    <!-- Hidden image for preloading -->
    <img src="@/assets/order-placed-succefully.gif" alt="order placed" style="display:none;">
    <!-- Pop-up 1 order placed succefully toodoo-->
    <div v-if="orderPlaced" loading="Lazy">
        <div
            class="fixed inset-0 items-center grid h-screen place-items-center justify-center bg-gray-800 bg-opacity-75 z-50">
            <div class="bg-white p-6 rounded-lg shadow-lg w-96">
                <img src="@/assets/order-placed-succefully.gif" alt="order placed">
                <h3 class="font-extrabold text-center text-4xl text-green-500 mt-2">تم تأكيد طلبكم</h3>
            </div>
        </div>
    </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
    name: 'ShoppingCart',
    data() {
        return {
            orderPlaced: false,
            clientName: '',
            clientTel: ''
        }
    },
    computed: {
        ...mapGetters(['cartItems', 'cartTotal']),
    },
    methods: {
        ...mapActions(['clearCart']),
        goBack() {
            window.history.back();
        },
        async getClientInfos() {
            const clientId = localStorage.getItem('clientId');
            if (!clientId) return;

            const base = `https://docs.google.com/spreadsheets/d/${process.env.VUE_APP_SHEET_ID}/gviz/tq?&sheet=listClients`;
            const query = encodeURIComponent(`Select D,E where I='${clientId}'`);
            const url = `${base}&tq=${query}`;

            try {
                const res = await fetch(url);
                const rep = await res.text();
                const data = JSON.parse(rep.substr(47).slice(0, -2));
                if (data.table.rows.length > 0) {
                    const clientData = data.table.rows[0].c;
                    this.clientName = clientData[0]?.v || '';
                    this.clientTel = clientData[1]?.v || '';
                }
            } catch (error) {
                console.error("Error fetching data: ", error);
            }
        },
        async placeOrder() {
            const apiURL = `https://${process.env.VUE_APP_DOMAIN}/api/send-bon-command`;
            const clientId = localStorage.getItem('clientId');
            if (!clientId) return;

            const orderData = {
                clientId: clientId,
                clientName: this.clientName,
                clientTel: this.clientTel,
                products: this.cartItems.map(product => ({
                    id: product[0].v,
                    name: product[2].v,
                    quantity: product.quantity,
                    price: product[6].v
                }))
            };

            try {
                const response = await fetch(apiURL, {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(orderData)
                });
                if (!response.ok) {
                    throw new Error('Failed to place order');
                }
                this.orderPlaced = true;
                const gifDuration = 1700; // Assuming the GIF duration is 1.7 seconds
                setTimeout(() => {
                    this.orderPlaced = false;
                    this.clearCart()
                    this.$router.push({ name: 'home' });
                }, gifDuration + 1000); // Add a second to gif animation before going back to home page
            } catch (error) {
                console.error('Failed to place order', error);
            }
        }
    },
    mounted() {
        this.getClientInfos();
    }
};
</script>

<style scoped>
.shadow-top {
    box-shadow: 0 -4px 6px rgba(0, 0, 0, 0.1);
    /* Customize shadow as needed */
}
</style>
